/*===================== GLOBAL STYLES =====================*/

:root {
   --main-bg-color: #f4f7f6;
   /* #f4f7f6 #ecf2f9 */
   --highlight: #008ae6;
   /* #99ddff #ADD8E6 #e37222 */
   --gray-text: #808080;
}

* {
   box-sizing: border-box;
}

header, section, footer, aside, nav, main, article, figure {
  display: block;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Quicksand', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-align: left;
  background-color: #f4f7f6;
  background-color: var(--main-bg-color);
  font-size: 18px;
  /* line-height: 28px; */
  color: #808080;
  color: var(--gray-text);
}

h1 {
  padding-left: 20px;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.4em;
}

img.narrow {
   width: 100%;
}

p {
  text-transform: none;
  font-size: 18px;
  line-height: 28px;
  /* margin-top: 0; */
}

.black {
  color: #000;
}

p a {
  color: #808080;
  color: var(--gray-text);
  /*text-decoration: none;*/
  transition: color 0.2s;
}

p a:hover {
  color: #008ae6;
  color: var(--highlight);
}

h2 {
  margin-top: 60px;
  font-weight: 500;
}

/* h2:first-child {
  margin-top: 0;
} */

b {
  font-family: 'Quicksand';
  font-weight: 700;
}

dl {
  margin-top: 30px;
}

dt {
  line-height: 28px;
  margin-bottom: 20px;
}

dd {
  margin-bottom: 30px;
  border-left: 1px solid #000;
  padding-left: 20px;
  line-height: 28px;
  margin-left: 30px;
}

/*===================== NAV STYLES =====================*/

header {
  position: fixed;
  width: 100%;
  background-color: #f4f7f6;
  background-color: var(--main-bg-color);
  letter-spacing: 0.3em;
  text-transform: uppercase;
  z-index: 1;
}

header a {
   text-decoration: none;
}

header h1 a {
   color: #000;
}

nav ul {
  margin-left: -28px;
  margin-bottom: 0px;
  padding-bottom: 12px;
  font-size: 12px;
  list-style-type: none;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
}

nav li {
  display: inline;
  padding: 5px 8px 13px 11px;
  border-width: 0px 1px 0px 0px;
  border-style: solid;
}

nav li:last-of-type {
  border-style: hidden;
}

nav li a {
  color: #000;
  /* text-decoration: none; */
  transition: color 0.2s;
}

nav li a:hover, nav li a.active {
  color: #008ae6;
  color: var(--highlight);
}

@media (min-width: 768px) {
  header {
    position: fixed;
    min-width: 0px;
    width: 160px;
    border-width: 0px 1px 0px 0px;
    border-style: solid;
  }

  nav ul {
    margin-left: 0;
    padding-bottom: 0;
    border-width: 0 0 0 0;
  }

  nav li {
    display: block;
    padding: 0px 0px 10px 0px;
    margin: 0px 0px 10px -22px;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
  }
}

/*===================== MAIN CONTENT STYLES =====================*/

.work {
  float: left;
  margin: 10px 0px 0px 0px;
}

.content {
  float: left;
  margin: 130px 20px 60px 20px;
}

.img-wrap.narrow img, .img-wrap.wide img {
  width: 100%;
  /* border: 1px solid #cccccc; */
}

.img-wrap {
  display: block;
  position: relative;
  margin-bottom: 100px;
  padding: 0px;
  cursor: pointer;
}

.img-wrap.margin {
   margin: 0 50px 100px 50px;
}

.img-wrap.wide {
  display: block;
  position: relative;
  padding: 0px;
  margin: 0 0 100px 0;
}

.img-wrap.no-description {
   cursor: default;
}

.img-wrap.active .img-description {
   opacity: 100;
}

.img-description {
  position: absolute;
  top: 0px;
  bottom: 4px;
  left: 0px;
  right: 0px;
  width: 100%;
  margin: 0px;
  background: rgba(255, 255, 255, 0.75);
  opacity: 0;
  transition: opacity 1s;
}

  .img-description p {
    position: absolute;
    top: 40%;
    bottom: 0px;
    left: 0px;
    right: 0px;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    text-align: center;
  }

.intro {
   display: block;
   height: 100vh;
   margin: 0px 20px 0px 20px;
}

.intro .text {
   position: absolute;
   bottom: 1%;
}

.intro.centered .text {
   position: absolute;
   bottom: 35%;
   padding-right: 20px;
}

.pills {
  padding: 0;
}

.pills li {
  border: 1px solid #808080;
  border: 1px solid var(--gray-text);
  padding: 6px 12px 6px 12px;
  list-style-type: none;
  display: inline-block;
  margin: 0 10px 10px 0;
  border-radius: 20px;
  font-size: 16px;
}

@media (min-width: 481px) {
  .img-wrap.narrow img, img.narrow {
    max-width: 400px;
  }

  .img-wrap.wide img {
    max-width: 1200px;
  }

  .img-wrap {
    max-width: 400px;
  }

  .img-wrap.margin.narrow img {
     max-width: 320px;
 }

  .img-wrap.wide {
    max-width: 1200px;
  }
}

@media (min-width: 768px) {
  .work {
    float: left;
    margin: 10px 0px 0px 190px;
  }

  .content {
    float: left;
    max-width: 800px;
    margin: 60px 10px 60px 250px;
  }

  .img-wrap.margin {
     margin: 0 0 100px 0;
  }

  .intro {
     margin: 0px 10px 0px 190px;
  }

  .intro .text {
     bottom: 1%;
 }

  .intro.centered .text {
     bottom: 50%;
 }
}

.animate {
  -webkit-animation: fade-in 1s linear forwards;
}

@-webkit-keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

.arrow {
  width: 40px;
}

.text-spaced {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}
